import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import * as style from "../../src/pages/projects/remote/styles.module.scss"
import Button from '@material-ui/core/Button';
import classNames from 'classnames'



class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state)
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/remote`)
    }

    return (
      <>
        
        <form
        className={style.loginpanel}
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
            navigate(`/remote`)
          }}
        >
            <h1>Password Protected</h1>
          <label className={style.logingrid}>
            Username
            <input type="text" name="username" onChange={this.handleUpdate} />
          </label>
          <label className={style.logingrid}>
            Password
            <input
              type="password"
              name="password"
              onChange={this.handleUpdate}
            />
          </label>
          <Button type="submit" className={classNames(style.pf_round_bt, style.pf_button_white )} variant="contained" >Login</Button>
          <Button size="small" href="/" className={classNames(style.pf_round_bt)} variant="outlined" >Back to Portfolio</Button>
         
        </form>
      </>
    )
  }
}

export default Login