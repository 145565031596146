import React from "react"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../services/auth"
import Button from '@material-ui/core/Button';
import * as style from "../../src/pages/projects/remote/styles.module.scss"


export default function NavBar() {
  let greetingMessage = ""
  if (isLoggedIn()) {
    greetingMessage = `Hello ${getUser().name}`
  } else {
    greetingMessage = "You are not logged in"
  }
  return (
    <div
      
    >
     
      <nav>
       
        {isLoggedIn() ? (
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logout(() => navigate(`/app/login`))
            }}
          >
            <Button className={style.pf_round_bt} variant="outlined" >Logout</Button>
          </a>
        ) : null}
      </nav>
    </div>
  )
}